<template>
    <v-app-bar absolute elevation="0">
    <v-app-bar-nav-icon  @click="$router.push({name: 'main'})" v-if="backward" data-cy="CameraNavBar-backward-btn">
       <template slot="default">
           <v-btn icon color="natural" ><v-icon>mdi-arrow-left</v-icon></v-btn>
       </template>
         </v-app-bar-nav-icon>
         <v-spacer/>
          <v-toolbar-title>
   <div class="text-title-1 natural--text">{{title}}</div>
    </v-toolbar-title>
        <v-spacer/>
<v-btn v-if="backward" icon></v-btn>
 <template v-slot:extension>
      <v-spacer>    <div class="text-title-3 natural--text">{{title}}</div></v-spacer>
        </template>
     </v-app-bar>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'หัวข้อแสดงผล H4' 
    },
    backward:{
      type: Boolean,
      default: false
    }
  },

}
</script>

<style>

</style>